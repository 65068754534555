import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import { createPinia } from 'pinia'

import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
loadFonts()
import {firebaseApp} from "../firebase";
import { VueFire, VueFireAuth } from 'vuefire'

const app = createApp(App)
.use(router)
.use(createPinia())
.use(vuetify)
.use(VueFire, {
  firebaseApp: firebaseApp,
  modules: [
    // ... other modules
    VueFireAuth(),
  ],
}).mount('#app')


console.log();

export { app, firebaseApp }