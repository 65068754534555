<template>
	<span class="pa-1">
		<v-dialog v-model="dialog">
			<template v-slot:activator="{ props }">
				<v-btn color="success" v-bind="props"> Add Question </v-btn>
			</template>
			<v-card>
				<v-card-title> Create Question </v-card-title>
				<v-card-text>
					<v-form>
						<question-form
							v-model:question_id="question_id"
							v-model:status="status"
							v-model:question_text="question_text"
							v-model:subquestion_text="subquestion_text"
							v-model:tooltip="tooltip"
							v-model:notes="notes"
							v-model:acute="acute"
							v-model:community="community"
							v-model:mental_health="mental_health"
							v-model:adult_social_care="adult_social_care"
							v-model:childrens_social_care="childrens_social_care"
							v-model:staff_survey="staff_survey"
							v-model:conditions="conditions"
							v-model:type="type"
							v-model:origin="origin"
						></question-form>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-btn color="secondary" @click="dialog = false">Cancel</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="submit">Submit</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</span>
</template>

<script setup>
import QuestionForm from '@c/admin/QuestionForm';
import { useQuestionsStore } from '@/stores/questions';
import { ref, defineProps } from 'vue';
const questionStore = useQuestionsStore();

const props = defineProps({
	section: String
});
let dialog = ref(false);
let question_id = ref(null);
let status = ref(null);
let question_text = ref(null);
let subquestion_text = ref(null);
let tooltip = ref(null);
let notes = ref(null);
let acute = ref(false);
let community = ref(false);
let mental_health = ref(false);
let adult_social_care = ref(false);
let childrens_social_care = ref(false);
let staff_survey = ref(false);
let conditions = ref(null);
let type = ref(null);
let origin = ref(null);

function submit() {
	let doc = {
		question_id: question_id.value,
		status: status.value,
		question_text: question_text.value,
		subquestion_text: subquestion_text.value,
		tooltip: tooltip.value,
		notes: notes.value,
		acute: acute.value,
		community: community.value,
		mental_health: mental_health.value,
		adult_social_care: adult_social_care.value,
		childrens_social_care: childrens_social_care.value,
		staff_survey: staff_survey.value,
		conditions: conditions.value,
		type: type.value,
		origin: origin.value
	};
	questionStore.create(doc, props.section).then(() => {
		dialog.value = false;
	});
}
</script>
