<template>
	<!-- <v-form ref="form" v-model="valid"> -->
		<tr>
			<td v-for="header in headers" :key="header">
				<v-text-field
					v-if="!bools.includes(header)"
					dense
					:value="value[header]"
					@input="($event) => $emit('input', header, $event.target.value)"
				>
				</v-text-field>
				<v-checkbox
					v-else
					hide-details
					:modelValue="value[header]"
					@update:modelValue="(v) => $emit('input', header, v)"
				></v-checkbox>
			</td>

			<td>
				<v-btn small icon @click="$emit('delete')">
					<v-icon>mdi-delete</v-icon>
				</v-btn>
			</td>
		</tr>
	<!-- </v-form> -->
</template>

<script setup>
import { defineProps } from 'vue';
	// defineEmits, ref, watch, onMounted 
defineProps({
	headers: Array,
	value: Object
});

// const emit = defineEmits(['input', 'delete']);

const bools = [
	'acute',
	'community',
	'mental_health',
	'adult_social_care',
	'childrens_social_care',
	'ambulance', 
	'primary_care',
	'staff_survey'
];

// let form = ref(null);
// let valid = ref(null);
// const rules = {};

// onMounted(() => {
// 	form.value.validate();
// });

// watch(valid, (value) => {
// 	emit('input', 'valid', value);
// });
</script>
