import { initializeApp } from 'firebase/app';
import { getFirestore, collection, doc, } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
// ... other firebase imports



export const firebaseApp = initializeApp({
	apiKey: 'AIzaSyBWQLEQqJTAFpWwgDUL7BVLCfOI1hClNrM',
	authDomain: 'mw-dma-question-tracking.firebaseapp.com',
	projectId: 'mw-dma-question-tracking',
	storageBucket: 'mw-dma-question-tracking.appspot.com',
	messagingSenderId: '873102988877',
	appId: '1:873102988877:web:bde005907cf42bf2eeae04'
});

// used for the firestore refs
export const db = getFirestore(firebaseApp);
export const functions = getFunctions(firebaseApp, "europe-west1")

// here we can export reusable database references
export const dmasRef = collection(db, 'dmas');
export const sectionsRef = collection(db, 'sections');
export const questionsRef = collection(db, 'questions');
export const commentsRef = collection(db, 'comments');
export const usersRef = collection(db, 'users');
export function getDocRef( collection, id ){
	return doc(db, collection, id);
}