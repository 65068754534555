<template>
	<span>{{ originText }}</span>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import { useQuestionsStore } from '@/stores/questions';
const props = defineProps({
	origin: String
});
let origins = computed(() => {
	let questionsStore = useQuestionsStore();
	let origins = questionsStore.origins;
	return origins;
});
let originText = computed(() => {
	if (!origin) {
		return null;
	}
	let item = origins.value.find((o) => o.value == props.origin);
	return item ? item.name : '';
});
</script>
