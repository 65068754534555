<template>
	<v-app>
		<top-navigation></top-navigation>
		<v-main>
			<v-sheet flat class="pa-8">
				<router-view/>
			</v-sheet>
			
		</v-main>
		<toast-message></toast-message>
	</v-app>
</template>

<style lang="less">
</style>

<script>
import TopNavigation from "@c/navigation/TopNavigation.vue";
import ToastMessage from  "@c/notices/Toast.vue"
export default {
	name: "App",
	data: () => ({}),
	computed: {
		isMobile(){
			return false;
			// return this.$vuetify.display.smAndDown
		},
		// len(){
			// return this.dmas.length();
		// },
		// auth(){
			// return useAuthStore().get();
		// },
	},
	components: {
		TopNavigation,
		ToastMessage,
	},
	provide() {
		return {
			isMobile: this.isMobile
		}
	},
};
</script>
